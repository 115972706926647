


<template>
    <div class="contract-section">
        <custom-table ref="loadCustomData" :loadData="loadData" :remove="remove" @rowClick="rowClick" :drawers="drawers" :edit="edit" :pageInfo="pageInfo"
            :statistical="statistical" :filters="filters" :tableColumns="tableColumns" />
        <projectChild v-if="showDetail" :detailData="detailData"></projectChild>
    </div>
</template>

<script>
import projectChild from "./child.vue"
import { CONTRACT_STATUS } from "@/constant/contract"
import * as moment from "moment"
import { list, add, remove, update, getTotalMoney } from "@/api/project.js"
import { customerList } from "@/api/customer";
export default {
    components:{
        projectChild
    },
    data() {
        return {
            
            showDetail:false,
            filters: [
                {
                    name: "项目名称",
                    value: "",
                    property: "projectName",
                    defaultValue() {
                        return ''
                    },
                    type: "input",
                    clearable: true
                }, {
                    name: "项目状态",
                    property: 'projectType',
                    value: 0,
                    type: 'select',
                     doSearch: true,
                    defaultValue() {
                        return 0
                    },
                    options:CONTRACT_STATUS,
                    clearable: false
                }
            ],
            drawers: [
                {
                    name: "项目名称",
                    value: "",
                    property: "projectName",
                    defaultValue() {
                        return ''
                    },
                    type: "input",
                    require: true,
                    clearable: true
                },
                //  {
                //     name: "签订日期",
                //     property: "projectCycle",
                //     value: null,
                //     type: 'date-rand',
                //     require: true,
                //     defaultValue() {
                //         return null
                //     },
                //     formatDrawer: (date) => {
                //         if (date) {
                //             return [new Date(date.split("~")[0]), new Date(date.split("~")[1])]
                //         }
                //         return null
                //     },
                //     formatCommit: (date) => {
                //         return moment(new Date(date[0])).format("YYYY-MM-DD") + '~' + moment(new Date(date[1])).format("YYYY-MM-DD")
                //     },

                //     clearable: true
                // },
                // {
                //     name: "客户",
                //     property: "customer",
                //     value: null,
                //     type: 'select',
                //     require: true,
                //     defaultValue() {
                //         return null
                //     },
                //     options:[],
                //     clearable: true
                // },
                // {
                //     name: "销售总额",
                //     property: "paymentAmount",
                //     value: 0,
                //     type: 'number',
                //     min: 0,
                //     require: true,
                //     defaultValue() {
                //         return 0
                //     },
                //     formatCommit(e) {
                //         return e.toFixed(2)
                //     },
                //     clearable: true
                // },  {
                //     name: "采购总额",
                //     property: "paymentAmount",
                //     value: 0,
                //     type: 'number',
                //     min: 0,
                //     require: true,
                //     defaultValue() {
                //         return 0
                //     },
                //     formatCommit(e) {
                //         return e.toFixed(2)
                //     },
                //     clearable: true
                // },
                // {
                //     name: "差旅费",
                //     property: "travelFee",
                //     value: 0,
                //     type: 'number',
                //     min: 0,
                //     require: true,
                //     defaultValue() {
                //         return 0
                //     },
                //     formatCommit(e) {
                //         return e.toFixed(2)
                //     },
                //     clearable: true
                // },  {
                //     name: "业务招待费",
                //     property: "businessEntertainmentFee",
                //     value: 0,
                //     type: 'number',
                //     min: 0,
                //     require: true,
                //     defaultValue() {
                //         return 0
                //     },
                //     formatCommit(e) {
                //         return e.toFixed(2)
                //     },
                //     clearable: true
                // },
                  {
                    name: "状态",
                    value: 0,
                    property: 'projectType',
                    require: true,
                    defaultValue() {
                        return 0
                    },
                    type: 'select',
                    clearable: false,
                    options: CONTRACT_STATUS
                }
               
            ],
            tableColumns: [
                {
                    name: "项目名称",
                    props: "projectName",
                },
                  {
                    name: "客户",
                    props: "customerName",
                },
               
                // {
                //     name: "项目周期",
                //     props: "projectCycle",
                //     isFormat: true,
                //     format: (date) => {
                //         if (date) {
                //             date = date.split("~")
                //             return moment(new Date(date[0])).format("YYYY-MM-DD") + '~' + moment(new Date(date[1])).format("YYYY-MM-DD")
                //         }
                //         return null
                //     },

                // }, 
                 {
                    name: "销售总额",
                    props: "salesTotalAmounts",
                    isFormat: true,
                    format: (date) => {
                        return "￥"+date
                    },
                    formatCommit: (date) => {
                        return moment(new Date(date)).format("YYYY-MM-DD")
                    }
                }, 
                 {
                    name:"未收款",
                     props: "salesNoReceivedMoney",
                    isFormat: true,
                    format: (date) => {
                        return "￥"+date
                    },
                    formatCommit: (date) => {
                        return moment(new Date(date)).format("YYYY-MM-DD")
                    }
                },
                {
                    name: "采购总额",
                    props: "purchaseTotalAmounts",
                    isFormat: true,
                    format: (date) => {
                        return "￥"+date
                    },
                    
                },{
                    name: "差旅费",
                    props: "travelFee",
                    isFormat: true,
                    isFormat: true,
                    format: (date) => {
                        return "￥"+date
                    },
                 
                },{
                    name: "业务招待费",
                    props: "businessEntertainment",
                    isFormat: true,
                    isFormat: true,
                    format: (date) => {
                        return "￥"+date
                    },
                },
                {
                    name: "操作",
                    isEdit: true,
                    edits: ['remove', 'edit']
                }
            ],
            pageInfo: {
                pageSize: 10,
                pageNumber: 1,
                total: 1000
            },
            totalData: {

            },
            //详细信息
            detailData:{

            }
        }
    },
    computed: {
        statistical() {

            let data = [
                {
                    name: "应收总额",
                    value: this.totalData.totalReceivable || 0
                },
                {
                    name: "已收总额",
                    value: this.totalData.totalHaveReceived || 0
                },
                {
                    name: "未收总额",
                    value: this.totalData.totalNoReceived || 0
                },
                {
                    name: "支出总额",
                    value: this.totalData.totalPayedMoney || 0
                }
            ]
            return data
        },

    },
    methods: {
        async customerList(){
              let data1 = await customerList({
        pageSize: 10000,
        pageNumber: 1,
        type: 0,
      });

      let list1 = data1.data.list;
      list1.map((e) => {
        e.type = e.name;
      });
      this.drawers[2].options = list1;
        },
        loadCustomData(){
            this.$refs['loadCustomData'].loadDatas()
        },  
        rowClick(data){
            this.showDetail=true
            this.detailData=data
        },
        async loadData(data) {
            
            let res = await list(data)
            this.totalData=res.data
            return {
                list: res.data.basicPageVo.list,
                total: res.data.basicPageVo.total
            }

        },
        async remove(row) {
            await remove({
                id: row.id
            })
        },
        async edit(data) {
            let httpMethod = data.drawerType == 'edit' ? update : add
            delete data['drawerType']
            if(data.drawerType!="edit"){
                data.travelFee=0
                data.businessEntertainmentFee=0
            }
            await httpMethod(data)
        },

    },
    created(){
        this.customerList()
    }


}
</script>
