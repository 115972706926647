<style lang="scss" scoped>
.child-detail {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  .main {
    width: 1000px;
    height: 100%;
    background: white;
    overflow-y: auto;
    padding: 12px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid $grow-min;

      .el-icon-close {
        cursor: pointer;
        font-size: 24px;
        // border-bottom: 1px solid $grow-min;
      }
    }

    .footer {
      display: flex;
      padding-top: 12px;
      border-top: 1px solid $grow-min;
      justify-content: center;
      margin-top: 12px;
    }

    .content {
      flex-grow: 1;
      overflow: hidden;
    }
  }
}
</style>

<style lang="scss">
.content {
  display: flex;
  flex-direction: column;

  .el-tab-pane {
    overflow-y: auto;
  }
}
</style>

<style lang="scss" scoped>
.content {
  .input-item {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .input-item-title {
      width: 100px;
      font-size: 14px;
    }
  }

  .custom-tablei1 {
    width: 900px;
    margin-top: 24px;

    .list-item {
      width: 100%;
      display: flex;
      margin-bottom: 12px;

      .row {
        flex-grow: 1;
        // width: 200px;
        flex-shrink: 0;
        // padding-right: 20px;
        font-size: 14px;
        border-bottom: 1px solid $grow-min;
        padding-bottom: 12px;
        padding-top: 12px;
      }
    }
  }
}

.contract-list-item {
  width: 1000px;
  padding: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
  // background: $grow-min;
  background: rgb(238, 238, 238);
  border-radius: 4px;

  .contrac-list-header {
    padding-bottom: 1px solid $white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .remove {
      font-size: 18px;
      cursor: pointer;
    }
  }
}
</style>

<template>
  <div class="child-detail">
    <div class="main">
      <!-- {{ detailData }} -->
      <div class="header">
        <div>业务项目详情</div>
        <div class="el-icon-close" @click="close"></div>
      </div>
      <div class="content">
        <el-tabs v-model="activeName">
          <!-- 销售合同 -->
          <el-tab-pane label="销售合同" name="first">
            <statistical-all :statistical="CtotalData1"></statistical-all>
            <div class="input-item">
              <div class="input-item-title">销售合同列表：</div>
              <div class="input-item-main">
                <el-button
                  type=""
                  v-show="contract1.length < 1"
                  @click="createCustract1"
                  >创建销售合同</el-button
                >
              </div>
            </div>

            <div
              class="contract-list-item"
              v-for="(item, index) of contract1"
              :key="'contract122' + index"
            >
              <div class="contrac-list-header">
                <div class="title">销售合同{{ index + 1 }}</div>
                <!-- <div
                  class="el-icon-delete remove"
                  @click="deleteCustomer1(item)"
                ></div> -->
              </div>
              <div class="input-item">
                <div class="input-item-title">销售总额：</div>
                <div class="input-item-main">
                  <el-input-number
                    v-model="item.contractMoneyAmount"
                    style="width:400px"
                    :min="0"
                  ></el-input-number>
                </div>
              </div>

              <div class="input-item">
                <div class="input-item-title">签订日期：</div>
                <div class="input-item-main">
                  <el-date-picker
                    v-model="item.signDate"
                    placement="bottom-start"
                    style="width:400px"
                  ></el-date-picker>
                </div>
              </div>
              <div class="input-item">
                <div class="input-item-title">客户：</div>
                <div class="input-item-main">
                  <el-select style="width:400px" v-model="item.customerName">
                    <el-option
                      v-for="i of customerList2"
                      :key="i.name"
                      :value="i.name"
                      >{{ i.name }}</el-option
                    >
                  </el-select>
                </div>
              </div>

              <div class="input-item">
                <div class="input-item-title">合同件pdf：</div>
                <div class="input-item-main">
                  <custom-file
                    :clearable="true"
                    v-model="item.url"
                    fileTypes=".pdf"
                  ></custom-file>
                </div>
              </div>

              <div class="input-item">
                <div class="input-item-title">收款/开票记录：</div>
                <div class="input-item-main">
                  <el-button type="" @click="createCustract1Child(item)"
                    >创建收款/开票记录</el-button
                  >
                </div>
              </div>

              <!-- 收款记录 -->
              <div class="custom-tablei1">
                <div class="list-item">
                  <div class="row" style="padding-right:50px">收款金额</div>
                  <div class="row" style="padding-right:50px">收款日期</div>
                  <div class="row" style="padding-right:50px">发票金额</div>
                  <div class="row">发票日期</div>
                  <div class="row">操作</div>
                </div>
                <div class="list-item" v-for="i of item.list" :key="i.id">
                  <div class="row">
                    <el-input-number
                      v-model="i.salesReceivedAmounts"
                      style="width:150px"
                    ></el-input-number>
                  </div>
                  <div class="row">
                    <el-date-picker
                      placement="bottom-start"
                      style="width:150px"
                      v-model="i.salesReceivedDate"
                    ></el-date-picker>
                  </div>
                  <div class="row">
                    <el-input-number
                      v-model="i.salesInvoiceAmounts"
                      style="width:150px"
                    ></el-input-number>
                  </div>
                  <div class="row">
                    <el-date-picker
                      v-model="i.salesInvoiceDate"
                      placement="bottom-start"
                      style="width:150px"
                    ></el-date-picker>
                  </div>
                  <div class="row">  <el-button
                    @click="removeScales(item.list,i)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                  ></el-button></div>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <!-- 采购合同 -->
          <el-tab-pane label="采购合同" name="second">
            <statistical-all :statistical="CtotalData2"></statistical-all>
            <div class="input-item">
              <div class="input-item-title">采购合同列表：</div>
              <div class="input-item-main">
                <el-button type="" @click="createCustract2"
                  >创建采购合同</el-button
                >
              </div>
            </div>

            <div
              class="contract-list-item"
              v-for="(item, index) of contract2"
              :key="'contract122' + index"
            >
              <div class="contrac-list-header">
                <div class="title">采购合同{{ index + 1 }}</div>
                <div
                  class="el-icon-delete remove"
                  @click="deleteCustomer2(item)"
                ></div>
              </div>
              <div class="input-item">
                <div class="input-item-title">采购总额：</div>
                <div class="input-item-main">
                  <el-input-number
                    v-model="item.contractMoneyAmount"
                    style="width:400px"
                    :min="0"
                  ></el-input-number>
                </div>
              </div>

              <div class="input-item">
                <div class="input-item-title">签订日期：</div>
                <div class="input-item-main">
                  <el-date-picker
                    v-model="item.signDate"
                    placement="bottom-start"
                    style="width:400px"
                  ></el-date-picker>
                </div>
              </div>
              <div class="input-item">
                <div class="input-item-title">供应商：</div>
                
                <div class="input-item-main">
                  <el-select style="width:400px" v-model="item.supplierName">
                    <el-option
                      v-for="i of customerList1"
                      :key="i.name"
                      :value="i.name"
                      >{{ i.name }}</el-option
                    >
                  </el-select>
                </div>
              </div>

              <div class="input-item">
                <div class="input-item-title">合同件pdf：</div>
                <div class="input-item-main">
                  <custom-file
                    :clearable="true"
                    v-model="item.url"
                    fileTypes=".pdf"
                  ></custom-file>
                </div>
              </div>

              <div class="input-item">
                <div class="input-item-title">付款/开票记录：</div>
                <div class="input-item-main">
                  <el-button type="" @click="createCustract2Child(item)"
                    >创建付款/开票记录</el-button
                  >
                </div>
              </div>

              <!-- 收款记录 -->
              <div class="custom-tablei1">
                <div class="list-item">
                <div class="row" style="padding-right:50px">付款金额</div>
                  <div class="row" style="padding-right:50px">付款日期</div>
                  <div class="row" style="padding-right:50px">发票金额</div>
                  <div class="row">发票日期</div>
                </div>
                <div class="list-item" v-for="i of item.list" :key="i.id">
                  <div class="row">
                    <el-input-number
                      v-model="i.purchasePayedAmounts"
                      style="width:200px"
                    ></el-input-number>
                  </div>
                  <div class="row">
                    <el-date-picker
                      placement="bottom-start"
                      style="width:200px"
                      v-model="i.purchasePayedDate"
                    ></el-date-picker>
                  </div>
                  <div class="row">
                    <el-input-number
                      v-model="i.purchaseInvoiceAmounts"
                      style="width:200px"
                    ></el-input-number>
                  </div>
                  <div class="row">
                    <el-date-picker
                      v-model="i.purchaseInvoiceDate"
                      placement="bottom-start"
                      style="width:200px"
                    ></el-date-picker>
                  </div>
                   <div class="row">  <el-button
                    @click="removePurch(item.list,i)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                  ></el-button></div>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <!-- 差旅费 -->
          <el-tab-pane label="差旅费" name="third">
            <statistical-all :statistical="CtotalData3"></statistical-all>

            <div class="input-item">
              <div class="input-item-title">费用记录：</div>
              <div class="input-item-main">
                <el-button type="" @click="addTravelExpense"
                  >创建差旅费记录</el-button
                >
              </div>
            </div>

            <div class="custom-tablei1">
              <div class="list-item">
                <div class="row">付款金额</div>
                <div class="row">付款日期</div>
                <div class="row">明细</div>
              </div>
              <div
                class="list-item"
                v-for="(item, index) of travelExpense"
                :key="'travelExpense' + index"
              >
                <div class="row">
                  <el-input-number
                    v-model="item.payAmount"
                    style="width:200px"
                  ></el-input-number>
                </div>
                <div class="row">
                  <el-date-picker
                    placement="bottom-start"
                    v-model="item.payDate"
                    style="width:200px"
                  ></el-date-picker>
                </div>
                <div class="row">
                  <el-input
                    v-model="item.detail"
                    style="width:200px"
                  ></el-input>
                </div>
                <div class="row">
                  <el-button
                    @click="deleteTravelExpense(item)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <!-- 业务招待费 -->
          <el-tab-pane label="业务招待费" name="fourth">
            <statistical-all :statistical="CtotalData4"></statistical-all>
            <div class="input-item">
              <div class="input-item-title">费用记录：</div>
              <div class="input-item-main">
                <el-button type="" @click="addBusinessEntertainment"
                  >创建差旅费记录</el-button
                >
              </div>
            </div>

            <div class="custom-tablei1">
              <div class="list-item">
                <div class="row">付款金额</div>
                <div class="row">付款日期</div>
                <div class="row">明细</div>
              </div>
              <div
                class="list-item"
                v-for="(item, index) of businessEntertainment"
                :key="'businessEntertainmentDefault' + index"
              >
                <div class="row">
                  <el-input-number
                    v-model="item.payAmount"
                    style="width:200px"
                  ></el-input-number>
                </div>
                <div class="row">
                  <el-date-picker
                    placement="bottom-start"
                    v-model="item.payDate"
                    style="width:200px"
                  ></el-date-picker>
                </div>
                <div class="row">
                  <el-input
                    v-model="item.detail"
                    style="width:200px"
                  ></el-input>
                </div>
                <div class="row">
                  <el-button
                    @click="removeBusinessEntertainment(item)"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="footer">
        <el-button type="primary" @click="commit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  getBusinessEntertainment,
  updateBusinessEntertainment,
  addBusinessEntertainment,
  deleteBusinessEntertainment,
  getTravelExpense,
  addTravelExpense,
  updateTravelExpense,
  deleteTravelExpense,
  addSalesContract,
  updateSalesContract,
  deleteSalesContract,
  getSalesContract,
  getBusinessAffix,
  updateBusinessAffix,
  addBusinessAffix,
  getPurchaseContract,
  addPurchaseContract,
  updatePurchaseContract,
  deletePurchaseContract,
  update
} from "../../../../api/project";

import { customerList } from "@/api/customer";

//销售合同附件
const contact1Child = () => {
  return {
    salesReceivedAmounts: 0,
    salesReceivedDate: null,
    salesInvoiceAmounts: 0,
    salesInvoiceDate: null,
  };
};

//采购合同附件
const contact2Child = () => {
  return {
    purchasePayedAmounts: 0,
    purchasePayedAmounts: null,
    purchasePayedDate: 0,
    purchaseInvoiceDate: null,
  };
};

//默认差旅费，业务招待费数据
const businessEntertainmentDefault = () => {
  return {
    payAmount: 0,
    payDate: null,
    detail: "",
  };
};

//默认合同数据
const contractData = () => {
  return {
    contractMoneyAmount: 0,
    customerName: "",
    signDate: null,
    url: "",
    list: [],
  };
};

//默认采购合同
const purchaseContractDefault = () => {
  return {
    contractMoneyAmount: 0,
    supplierName: null,
    signDate: null,
    url: "",
    list: [],
  };
};

export default {
  created() {
    this.businessId = this.detailData.id;
    this.initData();
    this.loadCustomList();
  },
  props: {
    detailData: Object,
  },
  data() {
    return {
      customerList1: [], //采购
      customerList2: [], //客户
      contract1: [], //采购合同
      contract2: [], //销售合同
      businessId: null,
      businessEntertainment: [],
      travelExpense: [],
      activeName: "first",
    };
  },
  computed: {
    CtotalData1() {
      let v1 = 0;
      let v2 = 0;
      this.contract1.map((e) => {
        v1 += parseFloat(e.contractMoneyAmount);
      });

      this.contract1.map((e) => {
        e.list.map((i) => {
          v2 += parseFloat(i.salesReceivedAmounts);
        });
      });

      return [
        {
          name: "销售总额",
          value: v1,
        },
        {
          name: "已收总额",
          value: v2,
        },
        {
          name: "未收总额",
          value: v1 - v2,
        },
      ];
    },
    CtotalData2() {
      let v1 = 0;
      let v2 = 0;
      this.contract2.map((e) => {
        v1 += parseFloat(e.contractMoneyAmount);
      });

      this.contract2.map((e) => {
        e.list.map((i) => {
          v2 += parseFloat(i.purchasePayedAmounts);
        });
      });

      return [
        {
          name: "采购总额",
          value: v1,
        },
        {
          name: "已付总额",
          value: v2,
        },
        {
          name: "未付总额",
          value: v1 - v2,
        },
      ];
    },

    CtotalData3() {
      let val = 0;
      this.travelExpense.map((e) => {
        val += parseFloat(e.payAmount);
      });
      return [
        // {
        //   name: "应付总额",
        //   value: this.detailData.travelFee,
        // },
        {
          name: "已付总额",
          value: val,
        },
      ];
    },
    CtotalData4() {
      let val = 0;
      this.businessEntertainment.map((e) => {
        val += parseFloat(e.payAmount);
      });
      return [
        // {
        //   name: "应付总额",
        //   value: this.detailData.businessEntertainmentFee,
        // },
        {
          name: "已付总额",
          value: val,
        },
      ];
    },
  },
  methods: {
    async removeScales(i,item){
      // if(item.id){

      // }
        i.splice(i.indexOf(item))
      this.$message.success("删除成功！")
    },
    async removePurch(i,item){
  i.splice(i.indexOf(item))
      this.$message.success("删除成功！")
    },
    async initData() {
      await this.getBusinessEntertainment();
      await this.getTravelExpense();
      await this.getContract1();
      await this.getContract2();
    },
    async getBusinessEntertainment() {
      try {
        let data = await getBusinessEntertainment({
          businessId: this.businessId,
        });
        this.businessEntertainment = data.data || [];
        // this.bu/
      } catch (error) {
        this.businessEntertainment = [];
      }
    },
    async getTravelExpense() {
      try {
        let data = await getTravelExpense({
          businessId: this.businessId,
        });
        this.travelExpense = data.data || [];
        // this.bu/
      } catch (error) {
        this.travelExpense = [];
      }
    },
    async getContract1() {
      let data = await getSalesContract({ businessId: this.businessId });
      let res = data.data || [];
      for (let child of res) {
        let ress = await getBusinessAffix({ salesContractId: child.id });
        child.list = ress.data || [];
      }
      this.contract1 = res;
    },
    async getContract2() {
      let data = await getPurchaseContract({ businessId: this.businessId });
      let res = data.data || [];
      for (let child of res) {
        //TODO获取list
        let ress = await getBusinessAffix({ purchaseContractId: child.id });
        child.list = ress.data || [];
      }
      this.contract2 = res;
    },
    addTravelExpense() {
      this.travelExpense.push(businessEntertainmentDefault());
    },
    async createCustract1() {
      let info = contractData();
      let data = await addSalesContract({
        ...info,
        businessId: this.businessId,
      });
      info.id = data.data;
      info.list = [];
      //   console.log(info);
      this.contract1.push(info);
    },
    async createCustract2() {
      let info = purchaseContractDefault();
      let data = await addPurchaseContract({
        ...info,
        businessId: this.businessId,
      });
      info.id = data.data;
      info.list = [];
      //   console.log(info);
      this.contract2.push(info);
    },
    async createCustract1Child(item) {
      if (!item.list) {
        item.list = [];
      }

      let data = contact1Child();
      item.list.push({
        ...data,
        salesContractId: item.id,
      });
    },
    async createCustract2Child(item) {
      if (!item.list) {
        item.list = [];
      }

      let data = contact2Child();
      item.list.push({
        ...data,
        purchaseContractId: item.id,
      });
    },
    async deleteCustomer1(item) {
      if (item.id) {
        deleteSalesContract({ id: item.id });
      }

      this.contract1.splice(this.contract1.indexOf(item), 1);
      this.$message.success("删除成功");
    },
    async deleteCustomer2(item) {
      if (item.id) {
        deletePurchaseContract({ id: item.id });
      }

      this.contract2.splice(this.contract2.indexOf(item), 1);
      this.$message.success("删除成功");
    },
    async addBusinessEntertainment() {
      this.businessEntertainment.push(businessEntertainmentDefault());
    },
    async deleteTravelExpense(item) {
      if (item.id) {
        await deleteTravelExpense({ id: item.id });
        this.$parent.loadCustomData();
      }

      this.travelExpense.splice(this.travelExpense.indexOf(item), 1);
      this.$message.success("删除成功");
    },
    async removeBusinessEntertainment(item) {
      if (item.id) {
        await deleteBusinessEntertainment({ id: item.id });
        this.$parent.loadCustomData();
      }

      this.businessEntertainment.splice(
        this.businessEntertainment.indexOf(item),
        1
      );
      this.$message.success("删除成功");
    },

    async loadCustomList() {
   {   let data1 = await customerList({
        pageSize: 10000,
        pageNumber: 1,
        type: 1,
      });

      let list1 = data1.data.list;
      list1.map((e) => {
        e.type = e.name;
      });
      this.customerList1 = list1;}

    {  let data2 = await customerList({
        pageSize: 10000,
        pageNumber: 1,
        type: 0,
      });

      let list2 = data2.data.list;
      list2.map((e) => {
        e.type = e.name;
      });
      this.customerList2 = list2;}
    },
    close() {
      this.$parent.showDetail = false;
    },
    checks() {
      return tur;
    },
    async commits() {
      //销售合同
      {
        for (let item of this.contract1) {
          if (item.signDate) {
            item.signDate = moment(item.signDate).format("YYYY-MM-DD");
          }
          //更新基础信息
          await updateSalesContract({
            ...item,
            businessId: this.businessId,
          });

          //循环子列表
          for (let child of item.list) {
            if (child.salesReceivedDate) {
              child.salesReceivedDate = moment(child.salesReceivedDate).format(
                "YYYY-MM-DD"
              );
            }
            if (child.salesInvoiceDate) {
              child.salesInvoiceDate = moment(child.salesInvoiceDate).format(
                "YYYY-MM-DD"
              );
            }
            if (child.id) {
              updateBusinessAffix(child);
            } else {
              addBusinessAffix(child);
            }
          }
        }
      }

      //采购合同
      {
        for (let item of this.contract2) {
          if (item.signDate) {
            item.signDate = moment(item.signDate).format("YYYY-MM-DD");
          }
          //更新基础信息
          await updatePurchaseContract({
            ...item,
            businessId: this.businessId,
          });

          //循环子列表
          for (let child of item.list) {
            if (child.purchasePayedDate) {
              child.purchasePayedDate = moment(child.purchasePayedDate).format(
                "YYYY-MM-DD"
              );
            }
            if (child.purchaseInvoiceDate) {
              child.purchaseInvoiceDate = moment(
                child.purchaseInvoiceDate
              ).format("YYYY-MM-DD");
            }
            if (child.id) {
              updateBusinessAffix(child);
            } else {
              addBusinessAffix(child);
            }
          }
        }
      }

      //业务招待费
      {
        for (let item of this.businessEntertainment) {
          if (item.payDate) {
            item.payDate = moment(item.payDate).format("YYYY-MM-DD");
          }

          if (item.id) {
            await updateBusinessEntertainment(item);
          } else {
            await addBusinessEntertainment({
              ...item,
              businessId: this.businessId,
            });
          }
        }
      }

      //差旅费
      {
        for (let item of this.travelExpense) {
          if (item.payDate) {
            item.payDate = moment(item.payDate).format("YYYY-MM-DD");
          }

          if (item.id) {
            await updateTravelExpense(item);
          } else {
            await addTravelExpense({ businessId: this.businessId, ...item });
          }
        }
      }

      //更新
      {
        this.detailData.travelFee=this.CtotalData3[0].value
        this.detailData.businessEntertainmentFee=this.CtotalData4[0].value
        update({
          ...this.detailData,
        })
      }
      // this.$message.success("提交成功")
    },
    async commit() {
      if (!this.checks) {
        return;
      }

      await this.commits();

      this.$parent.loadCustomData();

      this.$message.success("提交成功！");

      this.$parent.showDetail = false;
    },
  },
};
</script>
